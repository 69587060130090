










import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import Loading from "@/components/Loading.vue";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import { statuses } from "@/constants/productVariants";
import makeSelectOptions from "@common/util/makeSelectOptions";
import { tags } from "@/constants/products";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      loading: false,
      product: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(product) {
      const options = {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: `Products Variants Management${
            product ? ` - ${product.name}` : ""
          }`,
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Name",
              sortable: true,
            },
            description: {
              text: "Description",
              sortable: true,
            },
            sku: {
              text: "SKU",
              sortable: true,
            },
            product: {
              text: "Product",
              options: {
                label: true,
                subProp: "product.name",
                sortBy: "product.value",
              },
            },
            status: {
              text: "Status",
              sortable: true,
              options: {
                label: true,
                preTransform(value) {
                  return statuses[value];
                },
              },
            },
            fulfillmentPriority: {
              text: "Fulfillment Priority",
              sortable: true,
            },
            weight: {
              text: "Weight",
              sortable: true,
              options: {
                preTransform(value, item) {
                  return value || item.product?.weight;
                },
              },
            },
            tags: {
              text: "Tags",
              sortable: true,
              options: {
                labels: true,
              },
            },
            "totalQuantityStatus.importedQuantity": {
              text: "Imported Quantity",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            "totalQuantityStatus.exportedQuantity": {
              text: "Exported Quantity",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            "totalQuantityStatus.quantity": {
              text: "Current Quantity",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            requiredQuantity: {
              text: "Required Quantity",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            importRequiredQuantity: {
              text: "Import Required Quantity",
              sortable: true,
              options: {
                filter: "currency",
                preTransform(_, item) {
                  return (
                    (item.requiredQuantity || 0) -
                    (item.totalQuantityStatus?.quantity || 0)
                  );
                },
              },
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            generateProducts: product && {
              attrs: {},
              content: {
                text: "Generate",
                icon: "fas fa-cubes",
                iconAttrs: {
                  class: "ml-1",
                },
              },
              target: {
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled =
                        new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Generate Product Variants",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        const skuTemplateItems = [];
                        for (const { key } of product.properties) {
                          skuTemplateItems.push(`{${key}}`);
                        }
                        const skuTemplate = skuTemplateItems.length
                          ? `${product.sku}-${skuTemplateItems.join("-")}`
                          : product.sku;

                        attrs.xModel =
                          dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({
                          skuTemplate,
                        });
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  skuTemplate: {
                                    attrs: {
                                      label: "SKU Template",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Cập nhật",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "productVariants",
                                  "generateFromProduct",
                                  {
                                    productId: product._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insert: product && {},
            export: {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Export Excel",
                icon: "mdi-microsoft-excel",
              },
              target: {
                menu: {
                  content: {
                    items: {
                      simple: {
                        content: {
                          text: "Simple",
                        },
                        on: {
                          xClick({ self }) {
                            const dataTable = self.context().context();
                            let findAllOptions = dataTable.getFindAllOptions();
                            if (dataTable.model.value.length) {
                              findAllOptions = {
                                payload: JSON.stringify({
                                  filters: [
                                    {
                                      key: "_id",
                                      operator: "in",
                                      value: dataTable.model.value.map(
                                        (item) => item._id
                                      ),
                                    },
                                  ],
                                  limit: -1,
                                }),
                              };
                            }
                            coreApiClient.download(
                              "productVariants",
                              `@/export`,
                              findAllOptions
                            );
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          multiSelectActionButtons: {
            bulkDelete: {
              attrs: {
                color: "error",
                dark: false,
              },
              content: {
                text: "Delete",
                icon: "mdi-delete",
              },
              target: {
                confirm: {
                  content: {
                    content: {
                      html: "Delete product variants? This cannot be undone!",
                    },
                  },
                  on: {
                    async xYes({ self: dialog }) {
                      const yesBtn = dialog.yesBtnContainer.value;
                      const btn = dialog.context();
                      const dataTable = btn.context();
                      const ids = dataTable.model.value.map((item) => item._id);
                      yesBtn.loading.value = true;
                      const result = await dataTable.execute(
                        async () => {
                          return await coreApiClient.call(
                            "productVariants",
                            "bulkDelete",
                            {},
                            {
                              ids,
                            }
                          );
                        },
                        undefined,
                        {
                          disableLoading: true,
                          willRefresh: true,
                        }
                      );
                      yesBtn.loading.value = false;
                      if (result) {
                        dialog.hide();
                      }
                    },
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: {},
            },
          },
          findAllOptionsFilter(options) {
            if (product) {
              options.filters.push({
                key: "productId",
                operator: "equal_to",
                value: product._id,
              });
            }
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "productVariants",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item) {
              item.productId = product._id;
              return await coreApiClient.call(
                "productVariants",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "productVariants",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("productVariants", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Name",
                  required: true,
                },
              },
              sku: {
                attrs: {
                  label: "SKU",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Status",
                  required: true,
                  items: makeSelectOptions(statuses),
                },
                ext: {
                  defaultValue: "active",
                },
              },
              fulfillmentPriority: {
                type: "number",
              },
              weight: {
                type: "number",
                attrs: {
                  min: 0,
                  onkeyup: "if(value<0) value=0;",
                },
              },
              requiredQuantity: {
                type: "number",
                attrs: {
                  label: "Required Quantity",
                  min: 0,
                  onkeyup: "if(value<0) value=0;",
                },
              },
              tags: {
                type: "selectMultiple",
                attrs: {
                  items: tags.map((item) => ({
                    value: item,
                    text: item,
                  })),
                },
              },
            },
            insertForm: product && {
              content: {
                fieldNames: [
                  "name",
                  "sku",
                  "description",
                  "status",
                  "fulfillmentPriority",
                  "weight",
                  "requiredQuantity",
                  "tags",
                ],
              },
            },
            editForm: {
              content: {
                fieldNames: [
                  "name",
                  "sku",
                  "description",
                  "status",
                  "fulfillmentPriority",
                  "weight",
                  "requiredQuantity",
                  "tags",
                ],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      };

      if (product?.properties?.length) {
        const extraDisplayFields = {};
        const extraInputFields = [];
        for (const property of product.properties) {
          const key = `property_${property.key}`;
          extraDisplayFields[key] = {
            text: property.label,
            options: {
              subProp: `propertyValues.${property.key}`,
              transform(value) {
                return `<span class="font-weight-bold red--text">${value}</span>`;
              },
            },
          };
          const defaultInput: any = {
            type: "select",
            attrs: {
              label: property.label || property.key,
              required: true,
            },
            ext: {
              formProperty: `propertyValues.${property.key}`,
            },
          };
          defaultInput.attrs.items = property.selections;
          options.ext.default.defaultInputs[key] = defaultInput;
          extraInputFields.push(key);
        }
        options.content.displayFields = Object.assign(
          extraDisplayFields,
          options.content.displayFields
        );
        if (options.ext.default.insertForm) {
          options.ext.default.insertForm.content.fieldNames.unshift(
            ...extraInputFields
          );
        }
        if (options.ext.default.editForm) {
          options.ext.default.editForm.content.fieldNames.unshift(
            ...extraInputFields
          );
        }
      }
      return options;
    },
  },
  async created() {
    let product;
    const productId = this.$route.params.productId;
    if (productId) {
      this.loading = true;
      product = await coreApiClient.call("products", "findById", {
        id: productId,
      });
      this.loading = false;
    }
    this.product = product;
    this.tableOptions = this.makeTableOptions(product);
  },
});
